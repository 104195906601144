
import * as Components from "./Cravesunset"

export default function getCustomComponent () {
  return Components
}

export function getShopId () {
  return "cravesunset"
}

